import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import fastclick from 'fastclick'
import lazyPlugin from 'vue3-lazy'
import { createPinia } from 'pinia'
import { VueCookieNext } from 'vue-cookie-next'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import i18n from './i18n'
import mitt from 'mitt';
import VueCookies from 'vue3-cookies'

const emitter = mitt();

fastclick.attach(document.body)
const loadimage = require('./assets/Loading.gif')
const errorimage = require('./assets/Loading.gif')
const app = createApp(App);
// createApp(App)
    app.use(i18n)
    app.use(store)
    app.use(router)
    router.app = app
    app.use(VueCookies)
    app.use(createPinia())
    app.use(VueCookieNext)
    app.use(Toast)
    app.use(lazyPlugin, {
        preLoad: 1.3,
        loading: loadimage,
        error: errorimage,
        attempt: 1
    })
    app.config.globalProperties.emitter = emitter;
    app.mount('#app')
