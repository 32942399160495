<template>
    <nav class="navbar navbar-expand navbar-light bg-white osahan-nav-top p-0 px-2 sticky-top" style="margin-left: 0 !important;">
        <div class="container" style="padding: 0 5px;">
            <router-link class="navbar-brand mr-2" to="/" style="margin-right: 5px !important;"><img style="height: 26px;" src="../../assets/img/zain_gray.png" alt=""></router-link>
            <select v-model="$i18n.locale" style="padding-right: 0;margin-left: 10px;">
                <option  v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale" @click="ChangeAR(locale)" class="dropdown-menu dropdown-menu-right shadow-sm" >
                    <a class="nav-link pr-0" v-if="locale == 'ar'">عربي</a>
                    <a class="nav-link pr-0" v-if="locale == 'en'">English</a>
                    <!-- {{ locale }} -->
                </option>
            </select>
            <!-- <router-link to="/" class="navbar-brand mr-2" ><img src="../../assets/img/white-footer.png" alt=""></router-link> -->
                <!-- <form style="margin-left: 25px !important;" class="d-none d-sm-inline-block form-inline mr-auto my-2 my-md-0 mw-100 navbar-search">
                    <div class="input-group">
                        <input type="text" class="form-control shadow-none border-0" placeholder="بحث ..." aria-label="Search" aria-describedby="basic-addon2">
                        <div class="input-group-append">
                            <button class="btn btn-dark" type="button">
                                <i class="feather-search"></i>
                            </button>
                        </div>
                    </div>
                </form> -->
                <!-- <div class="nav-item dropdown no-arrow  ">
                    <a style="background:  #800347 !important; color: rgb(255, 255, 255); border: 1px solid #fb93cc; border-radius: 5px;" @click="gotoSpay()" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer mar-15" >إشتــراك</a>
                </div> -->
                <ul class="navbar-nav ml-auto d-flex align-items-center">
                    <!-- <li class="nav-item dropdown no-arrow d-sm-none">
                        <router-link class="nav-link dropdown-toggle" to="/" id="searchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="feather-search mr-2"></i>
                        </router-link>
                        <div class="dropdown-menu dropdown-menu-right p-3 shadow-sm animated--grow-in" aria-labelledby="searchDropdown">
                            <form class="form-inline mr-auto w-100 navbar-search">
                                <div dir="rtl" class="input-group">
                                    <input type="text" style="margin-right: 5px;" class="form-control border-0 shadow-none" placeholder="بحث ..." aria-label="Search" aria-describedby="basic-addon2">
                                    <div class="input-group-append">
                                        <button class="btn" type="button" style="border-radius: 12px 0 0 12px;">
                                            <i class="feather-search"></i>
                                        </button><div class="nav-item dropdown no-arrow  ">
                        <a style="background: #044871 !important;color: #fff;border: 1px solid #83cbce;border-radius: 5px;" @click="gotoSpay()" class="btn btn-sm header-btn btn-outline-dark ml-auto pointer mar-15" >إشتــراك</a>
                    </div>
                            </form>
                        </div>
                    </li> -->
                    <!-- <li class="nav-item dropdown no-arrow" > -->
                        <!-- <a style="padding-right: 0;" class="nav-link dropdown-toggle" href="#" id="searchDropdown22" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i style="font-size: 21px;" class="feather-user-plus mr-2"></i>
                        </a> -->
                        <!-- <div class="dropdown-menu dropdown-menu-right shadow-sm" > -->
                           
                        <!-- <select v-model="$i18n.locale" style="padding-right: 0;">
                            <option  v-for="(locale, i) in locales" :key="`locale-${i}`" :value="locale" @click="ChangeAR(locale)" class="dropdown-menu dropdown-menu-right shadow-sm" >
                                <a class="nav-link pr-0" v-if="locale == 'ar'">عربي</a>
                                <a class="nav-link pr-0" v-if="locale == 'en'">English</a>
                            </option>
                        </select> -->
                        <!-- </div> -->
                    <!-- </li> -->
                    <li class="nav-item dropdown no-arrow" v-if="showHeader">
                        <router-link class="nav-link pr-0" to="/Unsubscribes" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="color: #fff;">
                            {{ $t("unsubscription") }}
                        </router-link>
                    </li>
                    <li class="nav-item dropdown no-arrow " v-else>
                        <a style="padding-right: 0;" class="nav-link dropdown-toggle" href="#" id="searchDropdown22" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i style="font-size: 21px;" class="feather-user-plus mr-2"></i>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow-sm">
                            <a @click="gotoSpay()" to="/Puzzles" class="dropdown-item pointer">{{ $t("subscription") }}</a>
                            <router-link to="/Login" class="dropdown-item">{{ $t("login") }}</router-link>
                        </div>
                    </li>
                    
                    <li class="nav-item dropdown mr-2">
                        <a class="nav-link dropdown-toggle pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ $t("games") }}
                        </a>
                        <div class="dropdown-menu dropdown-menu-right shadow-sm">
                            <router-link to="/Puzzles" class="dropdown-item">{{ $t("puzzles") }}</router-link>
                            <router-link to="/Arcade" class="dropdown-item">{{ $t("arcade") }}</router-link>
                            <router-link to="/Strategy" class="dropdown-item">{{ $t("strategy") }}</router-link>
                            <router-link to="/Adventure" class="dropdown-item">{{ $t("adventure") }}</router-link>
                            <router-link to="/Sports" class="dropdown-item">{{ $t("sports") }}</router-link>
                        </div>
                    </li>
                    <li class="nav-item dropdown mr-2">
                        <router-link class="nav-link pr-0" to="/" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{ $t("home") }}
                        </router-link>
                    </li>
                    
                </ul>
            </div>
        </nav>
</template>

<script>
import { ref } from '@vue/reactivity';
import { useCookie } from 'vue-cookie-next'
import { useRoute, useRouter } from 'vue-router';
import useEventsBus from '../../eventBus';
export default {
    data() {
     return {
       showHeader: false,
     }
    },
   mounted() {
        if (this.$cookie.isCookieAvailable("msisdn")) {
           this.showHeader =  true;
        } else {
           this.showHeader = false;
        }
    },
    setup() {
        const cookie = useCookie();
        const locales = ref(["ar", "en"])
        const router = useRouter();
        const route = useRoute();
        const { emit } = useEventsBus()
        // emit('locale',locale)

        const ChangeAR = (locale) => {
            // router.go()
            // console.log("locale" + locale)
            cookie.setCookie("locale", locale)
            emit('locale',locale)
        };
        return {
            locales,
            ChangeAR
        }
    },
    methods: {
      gotoSpay() {
      window.open("https://dsplp.sd.zain.com/?p=2931245237", "_self");
    },
    }
//   components: { LocaleSwitcher },
//    mounted() {
//         const s = document.createElement('script');
//            s.type = '05c76cfdeeef076896d2c152-text/javascript';
//            s. src = '/include/js/custom.js';
//            document.body.appendChild(s);
//     },
}
</script>

<style>
.pointer {
  cursor: pointer;
}
</style>