<template>
    <section class="py-5" style="padding-top: 0rem !important;background: #9DB0B0;">
        <top-home />
    </section>
    <section class="pb-5">
        <puzzles-home />
    </section>
    <section class="pb-5">
        <strategy-home/>
    </section>
    <section class="pb-5">
        <sports-home/>
    </section>
    <section class="pb-5">
        <adventure-home/>
    </section>
    <section class="pb-5">
        <arcade-home/>
    </section>
</template>

<script>
import { watchEffect } from '@vue/runtime-core'
import AdventureHome from './HomePage/AdventureHome.vue'
import ArcadeHome from './HomePage/ArcadeHome.vue'
import PuzzlesHome from './HomePage/PuzzlesHome.vue'
import SportsHome from './HomePage/SportsHome.vue'
import StrategyHome from './HomePage/StrategyHome.vue'
import TopHome from './HomePage/TopHome.vue'
import { HTTPDSP } from '@/Api/http-dsp';
import { useRouter , useRoute } from "vue-router"
import { useCookie } from 'vue-cookie-next';
import { useToast } from "vue-toastification";
export default {
  components: { TopHome, PuzzlesHome, StrategyHome, SportsHome, AdventureHome, ArcadeHome },
   name: 'AppHome',
    setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const toast = useToast();
       watchEffect(() => {
        if (!cookie.isCookieAvailable("msisdn") && !cookie.isCookieAvailable("status")) {
          let urlParams = new URLSearchParams(window.location.search);
          if(urlParams.has('msisdn')) {
             HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+urlParams.get('msisdn')).then((res) => {
                if (res.data.status == 1 ) {
                    cookie.setCookie('msisdn', urlParams.get('msisdn'), { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('status', res.data.status, { expire: 60 * res.data.remming_minutes, })
                    cookie.setCookie('remming_minutes', res.data.remming_minutes, { expire: 60 * res.data.remming_minutes, })
                    toast.success("مرحبا بك معنا  ", { timeout: 1000 });
                    // if(cookie.getCookie("content_id")!=null){
                    //   toast.info("سوف يتم تحويلك الي المحتوي", { timeout: 2000 });
                    //   setTimeout(() =>  router.push({name: "Contents", params: { id: cookie.getCookie("content_id") } }), 2000);
                    // } else {
                      setTimeout(() => router.push({ path: "/" }), 2500);
                    // }
                } else if (res.data.status == 0 ) {
                  cookie.removeCookie('msisdn');
                    toast.error("لست مشترك في هذة الخدمة", { timeout: 1000 });
                    setTimeout(() => router.push({ path: "/" }), 2500);
                }
                
                })
            }
        }
      });
   }
}
</script>

<style>

</style>