const adventure = {
    namespaced: true,
    state: {
        adventure: []
    },
    mutations: {
        SET_GMAES(state, data) {
            state.adventure = data
        }
    },
    actions: {
        AdventureGames({commit}) {
            
            return fetch('https://games.gamepix.com/games?category=13faf451-da07-432b-b7da-73bdbbe03a8b&order=d', {
                method: 'get',
                headers: {
                   'content-type': 'application/json'
               }
           }).then(res => {
             if (!res.ok) {
               const error = new Error(res.statusText);
               error.json = res.json();
               throw error;
             }
       
             return res.json();
           }).then(json => {
                commit('SET_GMAES', json.data)
           }).catch(error => console.error(error))
        },
    },
} 

export default adventure