<template>
  <section class="py-4 osahan-copyright" style="background: rgb(157, 37, 92);padding-top: 2.5rem !important;">
    <div class="container">
        <div class="row" dir="rtl">
            <div class="col-xl-12">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 style="color: #fff;text-shadow: 1px 1px 0px #00000080;">{{ $t("discovertype") }}</h5>
                    <p style="color: #fff;text-align: right;text-shadow: 1px 1px 1px #00000080;">
                       {{ $t("discovertypedesc") }}
                    </p>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 style="color: #fff;text-shadow: 1px 1px 0px #00000080;">{{ $t("aboutus") }}</h5>
                    <p style="color: #fff;text-align: right;text-shadow: 1px 1px 1px #00000080;">
                         {{ $t("aboutusdesc") }}
                    </p>
                </div>
            </div>
            <div class="col-xl-12">
                <div class="d-flex align-items-center justify-content-between">
                    <p>
                    </p>
                    <p class="m-0">
                        <router-link to="/" class="text-secondary"><img style="width: 130px;" src="../../assets/img/zain_gray.png" alt=""/></router-link>
                        <router-link class="navbar-brand mr-2" to="/" style="font-size: 10px;font-weight: 600;color: #fff;"><img style="width: 47px;margin-right: 30px;" src="../../assets/img/white-footer.png" alt="">
                        </router-link>
                    </p>
                    <p class="m-0 text-muted">Powered by <a class="text-dark" target="_blank" href="https://www.albatros.sd/"> Albatros 2024</a></p>
                </div>
            </div>
        </div>
    </div>
</section>

</template>

<script>
export default {

}
</script>

<style>

</style>