import { createStore } from 'vuex'
import games from './modules/games'
import puzzles from './modules/categories/puzzles'
import strategy from './modules/categories/strategy'
import sports from './modules/categories/sports'
import adventure from './modules/categories/adventure'
import arcade from './modules/categories/arcade'
export default createStore({
  modules: {
    games,
    puzzles,
    strategy,
    sports,
    adventure,
    arcade
  }
})
