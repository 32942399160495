<template>
  <app-header/>
    <Suspense>
      <template #default>
        <app-home/>
      </template>
      <template #fallback>
        <app-loading />
      </template>
    </Suspense>
  <app-footer/>
</template>

<script>
import AppFooter from '../components/layouts/AppFooter.vue'
import AppHeader from '../components/layouts/AppHeader.vue'
import AppHome from '../components/Pages/AppHome.vue'
import AppLoading from '../components/Spinners/AppLoading.vue';
import { Suspense } from "vue";
export default {
  name: "Home",
  components: { AppHeader, AppHome, AppFooter, AppLoading, Suspense },
};
</script>
