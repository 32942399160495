export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العاب"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرئيسية"])},
  "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الالعاب"])},
  "puzzles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العــاب الألغاز"])},
  "arcade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العــاب التحدي"])},
  "strategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العــاب الأكشن"])},
  "adventure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العــاب مغامرة"])},
  "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العــاب رياضية"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اشترك  وتمتع بتسلية فريدة من نادي اللعب"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إشتــراك"])},
  "unsubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الغاء الاشتراك"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])},
  "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض المــزيد"])},
  "playnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["العب الان"])},
  "ballchampions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابطال الكرة"])},
  "ballchampionsdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أظهر كل مهاراتك في لعبة كرة القدم هذه. اختر فريق أحلامك وانضم إلى حركة كرة القدم سريعة الخطى"])},
  "bikemania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هوس الدراجة"])},
  "bikemaniadesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغلب على جميع العقبات بأسرع ما يمكن."])},
  "frozenfront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الجبهة المتجمدة"])},
  "frozenfrontdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([". اختر التكتيك المناسب للحرب واكسب خطوطك كقائد بارع في الجبهة المجمدة!"])},
  "defensearrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سهم الدفاع"])},
  "defensearrowdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دافع عن قلعتك ضد قوى الظلام التي لا يمكن تصورها والتي تحاول اختراق جدرانها الجبارة"])},
  "discovertype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف حسب النوع"])},
  "discovertypedesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أعلي كل صفحة ستجد الفئات الرئيسية مثل الرياضة الالغاز الاكشن و السباق"])},
  "aboutus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات عنا"])},
  "aboutusdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clik 2 Play  هو موقع ويب للألعاب عبر الإنترنت يقدم أفضل مجموعة مختارة من الألعاب  لتجربة أكثر إمتاعًا. أينما كنت ، يمكنك قضاء يوم كامل على، نحن نقدم اللعب الفوري دون تنزيل أو مكونات إضافية أو تسجيل دخول أو نوافذ منبثقة أو أي إلهاء. يمكنك اللعب على أي جهاز ، وجهاز الكمبيوتر المحمول ، والجهاز اللوحي ، والهاتف الذكي. نحن نقدم أفضل خدمة العاب على الإنترنت. لدينا مجموعة كبيرة من الألعاب على الإنترنت  مناسبة لجميع الأعمار وجميع المستويات"])}
}