<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="p-5 pb-lg-4 bg-dark">
                    <div class="d-flex align-item-center title mb-3">
                        <router-link to="/Puzzles" style="margin-right: auto !important;margin-left: 0 !important;" class="btn btn-sm btn-outline-dark ml-auto zain_LightItalic">{{ $t("seemore") }}</router-link>
                        <h5 class="m-0 font-weight-normal zain_LightItalic" style="color: rgb(157, 37, 92);">{{ $t("puzzles") }}</h5>
                    </div>
                    <div class="row" dir="rtl">
                        <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="game in puzzle" :key="game.id">
                            <div class="osahan-card pointer">
                                <a @click="getFeed(game)">
                                    <img class="img-fluid" v-lazy="game.thumbnailUrl" alt="">
                                    <div class="osahan-card-body mt-3">
                                        <template v-if="showlocale">
                                        <h6 class="text-dark mb-1" >{{ game.title_ar }}</h6>
                                        </template>
                                        <template v-else>
                                          <h6 class="text-dark mb-1" >{{ game.title_en }}</h6>
                                        </template>
                                        <p class="mb-0 text-muted">{{ $t("puzzles") }}</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { watch } from '@vue/runtime-core';
import useEventsBus from '../../../eventBus';
import { useCookie } from 'vue-cookie-next'
export default {
  name: 'PuzzlesHome',
    async setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const puzzle = ref([]);
      const showlocale = ref(true);
      const { bus } = useEventsBus()
      
      const loc = cookie.getCookie('locale')
      console.log("loc ====" + loc)

      watch(()=> bus.value.get('locale'), (locale) => {
          const [localeBus] = locale ?? []
          locale.value = localeBus
          console.log("locale ====" + locale.value)
          if(cookie.isCookieAvailable("locale")) {
            if (locale.value == 'ar') {
                showlocale.value = true;
            } else {
                showlocale.value = false;
            }
          }
      })

      try {
        await HTTP.get(`CatogeryById.php?Cat_id=Puzzles&LIMIT=8`).then((res) => {
          puzzle.value = res.data.getCatogery; 
        });
      } catch (err) {
        console.log(err);
      }
      
      const getFeed = (game) => {
          router.push({ name: "GamesDetails", params: { gid: game.gid } });
      };

      return {
        getFeed,
        puzzle,
        showlocale
      };

    }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>