<template>
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <div class="p-5 pb-lg-4 bg-dark">
                    <div class="d-flex align-item-center title mb-3">
                        <router-link to="/Arcade" style="margin-right: auto !important;margin-left: 0 !important;" class="btn btn-sm btn-outline-dark ml-auto zain_LightItalic">{{ $t("seemore") }}</router-link>
                        <h5 class="m-0 font-weight-normal zain_LightItalic" style="color: rgb(157, 37, 92);">{{ $t("arcade") }}</h5>
                    </div>
                    <div class="row" dir="rtl">
                        <div class="col-6 col-sm-4 col-lg-4 col-xl-3" v-for="game in arcade" :key="game.id">
                            <div class="osahan-card pointer">
                                 <a @click="getFeed(game)">
                                    <img class="img-fluid" v-lazy="game.thumbnailUrl" alt="">
                                    <div class="osahan-card-body mt-3">
                                        <template v-if="showlocale">
                                          <h6 class="text-dark mb-1" >{{ game.title_ar }}</h6>
                                        </template>
                                        <template v-else>
                                          <h6 class="text-dark mb-1" >{{ game.title_en }}</h6>
                                        </template>
                                        <p class="mb-0 text-muted">{{ $t("arcade") }}</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from '@vue/reactivity';
import { HTTP } from '@/Api/http-common';
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import useEventsBus from '../../../eventBus';
import { watch } from '@vue/runtime-core';
export default {
  name: 'ArcadeHome',
  async setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();
      const arcade = ref([]);
      const { bus } = useEventsBus()
      const showlocale = ref(true);

      watch(()=> bus.value.get('locale'), (locale) => {

           const [localeBus] = locale ?? []
           locale.value = localeBus
           console.log("locale ====" + locale.value)
           if (locale.value == 'ar') {
              showlocale.value = true;
           } else {
              showlocale.value = false;
           }
       })

      try {
        await HTTP.get(`CatogeryById.php?Cat_id=Arcade&LIMIT=8`).then((res) => {
          arcade.value = res.data.getCatogery; 
        });
      } catch (err) {
        console.log(err);
      }

      const getFeed = (game) => {
          router.push({ name: "GamesDetails", params: { gid: game.gid } });
      };

      return {
        getFeed,
        arcade,
        showlocale
      };

    }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}

</style>