// import axios from 'axios'
const games = {
    namespaced: true,
    state: {
        games: []
    },
    mutations: {
        SET_GMAES(state, data) {
            state.games = data
        }
    },
    actions: {
        LoadAllGames({commit}) {
            // const BASE_URL = process.env.VUE_APP_BASEURL

            return fetch('https://games.gamepix.com/games?sid=L37Y6', {
                method: 'get',
                headers: {
                   'content-type': 'application/json'
               }
           }).then(res => {
             if (!res.ok) {
               const error = new Error(res.statusText);
               error.json = res.json();
               throw error;
             }
       
             return res.json();
           }).then(json => {
                commit('SET_GMAES', json.data)
           }).catch(error => console.error(error));

            // axios
            //      .get('http://196.202.135.251/telesisAPI/APIs/getPost.php?LIMIT=12&OFFSET=0')
            //      .then(res => {
            //          commit('SET_GMAES', res.data.getPost)
            //      })
            //      .catch(error => console.error(error))
        }
    },
} 

export default games