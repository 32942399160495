import { createI18n } from 'vue-i18n'
// import getBrowserLocale from "@/util/i18n/get-browser-locale"
// import { supportedLocalesInclude } from "./util/i18n/supported-locales"
// import {
//   getChoiceIndex,
//   setDefaultChoiceIndexGet
// } from "./util/i18n/choice-index-for-plural"
// import dateTimeFormats from "@/locales/date-time-formats"
// import numberFormats from "@/locales/number-formats"
// import useEventsBus from "@/eventBus"
/**
 * Load locale messages
 *
 * The loaded `JSON` locale messages is pre-compiled by `@intlify/vue-i18n-loader`, which is integrated into `vue-cli-plugin-i18n`.
 * See: https://github.com/intlify/vue-i18n-loader#rocket-i18n-resource-pre-compilation
 */

//  function getStartingLocale() {
//   const browserLocale = getBrowserLocale({ countryCodeOnly: true })

//   if (supportedLocalesInclude(browserLocale)) {
//     return browserLocale
//   } else {
//     return process.env.VUE_APP_I18N_LOCALE || "en"
//   }
// }

// setDefaultChoiceIndexGet(createI18n.prototype.getChoiceIndex)

// createI18n.prototype.getChoiceIndex = getChoiceIndex

// const startingLocale = getStartingLocale()


// const loadedLanguages = []

function loadLocaleMessages() {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key).default
    }
  })
  return messages
}


export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'ar',
  fallbackLocale: 'en',
  messages: loadLocaleMessages()
})
