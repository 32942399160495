const strategy = {
    namespaced: true,
    state: {
        strategy: []
    },
    mutations: {
        SET_GMAES(state, data) {
            state.strategy = data
        }
    },
    actions: {
        StrategyGames({commit}) {
            return fetch('https://games.gamepix.com/games?category=5690a2a7-24a5-4ad5-80d0-289a7e53fd37', {
                method: 'get',
                headers: {
                   'content-type': 'application/json'
               }
           }).then(res => {
             if (!res.ok) {
               const error = new Error(res.statusText);
               error.json = res.json();
               throw error;
             }
       
             return res.json();
           }).then(json => {
                commit('SET_GMAES', json.data)
           }).catch(error => console.error(error))
        },
    },
} 

export default strategy