<template>
  <div class="container">
            <div class="row">
                <div class="col-lg-12" style="padding-right:  0;padding-left: 0;">
                    <div id="slider">
                        <div class="slides">
                            <a @click="getFirst()" >
                            <div class="slider pointer">
                                <div class="legend"></div>
                                <div class="content">
                                    <div class="content-txt">
                                        <h1>{{ $t("ballchampions") }}</h1>
                                        <h2>{{ $t("ballchampionsdesc") }}</h2>
                                    </div>
                                </div>
                                <div class="image">
                                    <img src="../../../assets/image/soccer.png">
                                </div>
                            </div>
                            </a>
                                <a @click="getSecand()">
                                <div class="slider pointer">
                                    <div class="legend"></div>
                                    <div class="content">
                                        <div class="content-txt">
                                            <h1>{{ $t("bikemania") }}</h1>
                                            <h2>{{ $t("bikemaniadesc") }}</h2>
                                        </div>
                                    </div>
                                    <div class="image">
                                        <img src="../../../assets/image/moto_m.png">
                                    </div>
                                </div>
                                </a>
                                <a @click="getTherd()">
                                <div class="slider pointer">
                                    <div class="legend"></div>
                                    <div class="content">
                                        <div class="content-txt">
                                            <h1>{{ $t("frozenfront") }}</h1>
                                            <h2>{{ $t("frozenfrontdesc") }}</h2>
                                        </div>
                                    </div>
                                    <div class="image">
                                        <img src="../../../assets/image/1941.png">
                                    </div>
                                </div>
                                </a>
                                <a @click="getFour()">
                                <div class="slider pointer">
                                    <div class="legend"></div>
                                    <div class="content">
                                        <div class="content-txt">
                                            <h1>{{ $t("defensearrow") }}</h1>
                                            <h2>{{ $t("defensearrowdesc") }}</h2>
                                        </div>
                                    </div>
                                    <div class="image">
                                        <img src="../../../assets/image/arc.png">
                                    </div>
                                </div>
                                </a>
                            </div>
                            <div class="switch">
                                <ul>
                                    <li>
                                        <div class="on"></div>
                                    </li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
</template>

<script>
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
export default {
   name: 'TopHome',
   async setup() {
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie();

      const getFirst = () => {
          router.push({ name: "GamesDetails", params: { gid: 'C8S62' } });
      };
      const getSecand = () => {
          router.push({ name: "GamesDetails", params: { gid: 'OM649' } });
      };
      const getTherd = () => {
          router.push({ name: "GamesDetails", params: { gid: '40263' } });
      };
      const getFour = () => {
          router.push({ name: "GamesDetails", params: { gid: '27115' } });
      };

      return { getFirst ,getSecand, getTherd, getFour };
   }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>