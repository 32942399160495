
const puzzles = {
    namespaced: true,
    state: {
        puzzles: [],
    },
    mutations: {
        SET_GMAES(state, data) {
            state.puzzles = data
        },
    },
    actions: {
        PuzzlesGames({commit}) {
            
            return fetch('https://games.gamepix.com/games?sid=L37Y6&category=a46f6ba4-0fe9-419e-945c-12d4ab0cf582&order=d', {
                method: 'get',
                headers: {
                   'content-type': 'application/json'
               }
           }).then(res => {
             if (!res.ok) {
               const error = new Error(res.statusText);
               error.json = res.json();
               throw error;
             }
       
             return res.json();
           }).then(json => {
                commit('SET_GMAES', json.data)
           }).catch(error => console.error(error))
        },
    },
    
} 

export default puzzles