export default {
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
  "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "games": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Games"])},
  "puzzles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puzzles"])},
  "arcade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arcade"])},
  "strategy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strategy"])},
  "adventure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adventure"])},
  "sports": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscribe and enjoy a unique entertainment from the play club"])},
  "subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subscription"])},
  "unsubscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UnSubscription"])},
  "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "seemore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See More"])},
  "playnow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Play Now"])},
  "ballchampions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ball champions"])},
  "ballchampionsdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Show all your skills in this soccer game. Choose your dream team and join the fast-paced soccer action"])},
  "bikemania": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bike mania"])},
  "bikemaniadesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overcome all obstacles as fast as you can."])},
  "frozenfront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frozen front"])},
  "frozenfrontdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose the right tactic of war and win your lines as an accomplished commander in the frozen front!"])},
  "defensearrow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["defense arrow"])},
  "defensearrowdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defend your castle against unimaginable dark forces trying to breach its mighty walls"])},
  "discovertype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discover by type"])},
  "discovertypedesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At the top of each page you will find the main categories such as sports, puzzles, action and racing"])},
  "aboutus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["about us"])},
  "aboutusdesc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clik 2 Play is an online gaming website that offers the best selection of games for a more enjoyable experience. Wherever you are you can spend an entire day on We offer instant play without downloading, plugins, login, popups, or any distraction. You can play on any device, laptop, tablet, and smartphone. We provide the best online gaming service. We have a large selection of online games suitable for all ages and all levels"])}
}