const sports = {
    namespaced: true,
    state: {
        sports: []
    },
    mutations: {
        SET_GMAES(state, data) {
            state.sports = data
        }
    },
    actions: {
        SportsGames({commit}) {
            return fetch('https://games.gamepix.com/games?category=1f9c4256-9f5c-4463-b072-c4d2f266719e', {
                method: 'get',
                headers: {
                   'content-type': 'application/json'
               }
           }).then(res => {
             if (!res.ok) {
               const error = new Error(res.statusText);
               error.json = res.json();
               throw error;
             }
       
             return res.json();
           }).then(json => {
                commit('SET_GMAES', json.data)
           }).catch(error => console.error(error))
        },
    },
} 

export default sports