const arcade = {
    namespaced: true,
    state: {
        arcade: [],
    },
    mutations: {
        SET_GMAES(state, data) {
            state.arcade = data
        }
    },
    actions: {
        ArcadeGames({commit}) {
            return fetch('https://games.gamepix.com/games?category=e6e804fa-d074-4418-9b1c-a8db99364e3a', {
                method: 'get',
                headers: {
                   'content-type': 'application/json'
               }
           }).then(res => {
             if (!res.ok) {
               const error = new Error(res.statusText);
               error.json = res.json();
               throw error;
             }
       
             return res.json();
           }).then(json => {
                commit('SET_GMAES', json.data)
           }).catch(error => console.error(error))
        },
    },
} 

export default arcade